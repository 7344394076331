<template>
  <svg
    width="285"
    height="101"
    viewBox="0 0 285 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1363_4804)">
      <path
        d="M285 0.5H0V104.667H285V0.5Z"
        fill="url(#paint0_linear_1363_4804)"
      />
      <g
        style="mix-blend-mode:soft-light"
        opacity="0.4"
      >
        <mask
          id="mask0_1363_4804"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="285"
          height="105"
        >
          <path
            d="M285 0.5H0V104.667H285V0.5Z"
            fill="url(#paint1_linear_1363_4804)"
          />
        </mask>
        <g mask="url(#mask0_1363_4804)">
          <g
            style="mix-blend-mode:soft-light"
            opacity="0.35"
          >
            <path
              d="M142.5 50.5001L0.0400391 -50.1458V-85.1458H33.64L142.5 50.5001Z"
              fill="#F5FCFB"
            />
            <path
              d="M142.5 50.5001L83.8101 -85.1458H117.42L142.5 50.5001Z"
              fill="#F5FCFB"
            />
            <path
              d="M142.5 50.5001L167.59 -85.1458H201.19L142.5 50.5001Z"
              fill="#F5FCFB"
            />
            <path
              d="M284.96 -50.1458L142.5 50.5001L251.36 -85.1458H284.96V-50.1458Z"
              fill="#F5FCFB"
            />
            <path
              d="M142.5 50.5002L0.0400391 28.6147V-6.38525L142.5 50.5002Z"
              fill="#F5FCFB"
            />
            <path
              d="M284.96 28.6147L142.5 50.5002L284.96 -6.38525V28.6147Z"
              fill="#F5FCFB"
            />
            <path
              d="M142.5 50.5L0.0400391 107.375V72.375L142.5 50.5Z"
              fill="#F5FCFB"
            />
            <path
              d="M284.96 107.375L142.5 50.5L284.96 72.375V107.375Z"
              fill="#F5FCFB"
            />
            <path
              d="M33.64 186.135H0.0400391V151.135L142.5 50.5L33.64 186.135Z"
              fill="#F5FCFB"
            />
            <path
              d="M117.42 186.135H83.8101L142.5 50.5L117.42 186.135Z"
              fill="#F5FCFB"
            />
            <path
              d="M201.19 186.135H167.59L142.5 50.5L201.19 186.135Z"
              fill="#F5FCFB"
            />
            <path
              d="M284.96 186.135H251.36L142.5 50.5L284.96 151.135V186.135Z"
              fill="#F5FCFB"
            />
          </g>
        </g>
      </g>
      <path
        d="M152.992 57H177.382C177.757 57 178.1 57.2095 178.271 57.5427L186.992 74.5V90C186.992 90.5523 186.544 91 185.992 91H152.992V57Z"
        fill="#198055"
      />
      <path
        d="M167.992 64.5C167.992 63.9477 168.44 63.5 168.992 63.5H177.374C177.753 63.5 178.099 63.714 178.269 64.0528L182.769 73.0528C183.101 73.7177 182.618 74.5 181.874 74.5H168.992C168.44 74.5 167.992 74.0523 167.992 73.5V64.5Z"
        fill="#D2DCCD"
      />
      <mask
        id="path-18-inside-1_1363_4804"
        fill="white"
      >
        <rect
          x="105.992"
          y="46"
          width="54"
          height="45"
          rx="1"
        />
      </mask>
      <rect
        x="105.992"
        y="46"
        width="54"
        height="45"
        rx="1"
        fill="#D2DCCD"
        stroke="#198055"
        stroke-width="6"
        mask="url(#path-18-inside-1_1363_4804)"
      />
      <path
        d="M105.992 81H159.992V91H106.992C106.44 91 105.992 90.5523 105.992 90V81Z"
        fill="#198055"
      />
      <circle
        cx="124.992"
        cy="91"
        r="7.5"
        fill="#D2DCCD"
        stroke="#198055"
        stroke-width="3"
      />
      <circle
        cx="166.992"
        cy="91"
        r="7.5"
        fill="#D2DCCD"
        stroke="#198055"
        stroke-width="3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123.751 63.3793C128.799 59.0691 132 52.6583 132 45.5C132 32.5213 121.479 22 108.5 22C95.5213 22 85 32.5213 85 45.5C85 58.4787 95.5213 69 108.5 69C112.179 69 115.66 68.1547 118.76 66.6479L123.029 68.3288L123.751 63.3793Z"
        fill="#FA6338"
      />
      <path
        d="M94.7957 53.8483L93.7304 49.8723L97.8832 48.7596L97.415 47.0121L93.2621 48.1249L92.5494 45.4648L97.0984 44.2459L96.6093 42.4207L89.9238 44.212L92.6592 54.4208L94.7957 53.8483ZM101.182 43.0455L103.036 42.5488C104.097 42.2645 104.925 42.6947 105.185 43.6639C105.448 44.6473 104.974 45.3962 103.899 45.6843L102.024 46.1866L101.182 43.0455ZM102.464 47.828L104.211 47.3597L107.106 50.5499L109.525 49.9015L106.294 46.4453C107.343 45.6637 107.719 44.3497 107.37 43.048C106.826 41.0176 105.113 40.1421 102.87 40.743L98.5831 41.8918L101.319 52.1005L103.455 51.528L102.464 47.828ZM117.653 45.7674L113.026 47.0071L112.361 44.5239L116.726 43.3543L116.273 41.6635L111.908 42.8331L111.269 40.4489L115.896 39.2092L115.407 37.3839L108.643 39.1962L111.379 49.4049L118.142 47.5926L117.653 45.7674ZM126.666 43.3523L122.039 44.5921L121.374 42.1089L125.739 40.9393L125.286 39.2484L120.921 40.418L120.282 38.0339L124.909 36.7941L124.42 34.9689L117.656 36.7811L120.392 46.9898L127.155 45.1776L126.666 43.3523Z"
        fill="white"
      />
      <path
        d="M96.0271 83H102.027"
        stroke="#198055"
        stroke-width="2"
      />
      <path
        d="M99.0271 88H102.027"
        stroke="#198055"
        stroke-width="2"
      />
      <path
        d="M102.027 78L93.0271 78"
        stroke="#198055"
        stroke-width="2"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1363_4804"
        x1="142.5"
        y1="0.5"
        x2="141.911"
        y2="104.666"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#198055" />
        <stop
          offset="1"
          stop-color="#198055"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1363_4804"
        x1="142.5"
        y1="148.905"
        x2="142.5"
        y2="1.18359"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5FCFB" />
        <stop
          offset="0.22"
          stop-color="#83BCA5"
        />
        <stop
          offset="1"
          stop-color="#198055"
        />
      </linearGradient>
      <clipPath id="clip0_1363_4804">
        <rect
          width="285"
          height="100"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FreeShipping'
})
</script>
